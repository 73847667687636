import React from "react";
import './Info.css';

function Info({ }) {
    return (

        <div className="info">
            <p>
                A protocol is a set of explicit or implicit rules that guide behavior and facilitate coordinated action.
                <br/>
                <br/>
                This list of protocols has been curated to build your intuition for what a protocol is, how to recognize them, and the implications of their design.</p>
        </div>
    );

}


export default Info;