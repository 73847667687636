import React from 'react';
import './Footer.css';

function Footer ({}) {
        return (
            <div className='footer'>
                This site was created during and funded by the <a href='https://summerofprotocols.com/'>Summer of Protocols</a> 2024 PILL program.
<br/>
{/* Designed and developed by <a href='https://willieshaw.com'>Willie Shaw Fineberg</a>.
<br/> */}
License: <a href='https://creativecommons.org/licenses/by-nc/4.0/'>CC BY-NC 4.0</a>

            </div>
        );
}

export default Footer;